<template>

    <!-- content -->
    <div id="content" class="content board-list search-result">
        <div class="container">
            <!-- 페이지 타이틀 -->
            <div class="page-title">
                <h2 class="tit">{{ pageVO.pageName }}</h2>
            </div>

            <!--			<div class="content" v-html="pageVO.contentHtml"></div>-->
            <html-open :html-code="pageVO.contentHtml"></html-open>

        </div>
    </div>
    <!--//content -->

</template>

<script>
import HtmlOpen from "@/components/display/htmlOpen";

export default {
    name: "appPage",
    components: {HtmlOpen},
    data() {

        return {
            pageCode: this.$route.params.PAGE_CODE,
            pageVO: {},
        }
    },
    created() {

        this.getStaicPage();

    },
    mounted() {

    },
    methods: {

        getStaicPage() {

            this.$eventBus.$emit("startProgress")
            console.log(this.pageCode)
            this.axiosCaller.get(this, this.APIs.STATIC_PAGE + "/" + this.pageCode, {}, (res) => {

                this.$eventBus.$emit("doneProgress")
                const result = res.data
                if (result.status === "ok") {
                    this.pageVO = result.pageVO
                }

                if (result.status === "fail") {
                    this.swalUtils.gritter(result.status, result.msg, "warning", 3000)
                }

            })

        }

    }
}
</script>

<style scoped>

</style>